// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import 'assets/utils/variables';
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$i-a_reinvention_web-primary: mat.define-palette($primary);
$i-a_reinvention_web-accent: mat.define-palette($accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$i-a_reinvention_web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$i-a_reinvention_web-theme: mat.define-light-theme(
  (
    color: (
      primary: $i-a_reinvention_web-primary,
      accent: $i-a_reinvention_web-accent,
      warn: $i-a_reinvention_web-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($i-a_reinvention_web-theme);
// @import "~@ng-select/ng-select/themes/material.theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

// @import 'https://code.highcharts.com/css/highcharts.css';

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  font-size: 13px !important;
}

.layout-full-sidenav > header,
.layout-full-sidenav > .header {
  grid-column-start: 2;
  grid-row-start: 1;
}

.layer-5 {
  z-index: 5000 !important;
}

.layout-full-sidenav {
  display: grid;
  grid-template-columns: 5% minmax(min-content, 1fr); // auto;
  grid-template-rows: auto minmax(min-content, 1fr) auto;
}

@media (min-resolution: 120dpi) {
  // for scaling 125%
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: 1200px) {
  .layout-full-sidenav {
    grid-template-columns: 10% minmax(min-content, 1fr); // auto;
  }
}

@media screen and (min-width: 1200px) {
  .layout-full-sidenav {
    grid-template-columns: 7% minmax(min-content, 1fr); // auto;
  }
}

@media screen and (min-width: 1400px) {
  .layout-full-sidenav {
    grid-template-columns: 6% minmax(min-content, 1fr); // auto;
  }
}

.layout-full-sidenav > .nav-container {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: span 4;
  background: #1a1628;
}

// .layout-full-sidenav > .nav-container > nav, .layout-full-sidenav > .nav-container > .nav {
.primary-nav {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr;
  background: linear-gradient(
    180deg,
    #1a1628 -1.83%,
    #1a1628 -1.82%,
    rgba(2, 45, 66, 0.1) 6.39%,
    rgba(113, 110, 121, 0.4) 50.47%,
    #2f2c3c 111%
  );
  padding: 0.5em 0em 1.5em 0em;
  overflow: hidden;
}

.toolbar.toolbar-vertical {
  flex-direction: column;
  height: 100%;
}

.toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.toolbar.toolbar-vertical.toolbar.toolbar-labels-below > a {
  text-align: center;
}

.layout-full-sidenav > .nav-container > nav .link-action,
.layout-full-sidenav > .nav-container > .nav .link-action {
  width: 5em;
  background: transparent;
}

.toolbar.toolbar-labels-below > a {
  flex-direction: column;
}

.toolbar.toolbar-vertical > a {
  padding: 0.75em 0.5em 0.75em 0.5em;
}

.master-style .link-action {
  color: #2f2c3c;
  background: transparent;
}

.toolbar > a {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 1em 1em 1em;
}

.layout-full-sidenav > .nav-container > nav .side-nav-label,
.layout-full-sidenav > .nav-container > .nav .side-nav-label {
  font-size: 0.6875em;
  font-weight: 600;
}

.toolbar.toolbar-labels-below > a > * {
  padding: 0em 0em 0em 0em;
}

.toolbar > a > .icon + *,
.toolbar > a > * {
  cursor: pointer;
}

.padding-0-1-1-1 {
  padding: 0em 0.5em 0.5em 0.5em !important;
}

// card design
.mat-mdc-card {
  border-radius: 8px !important;
  // background: #fff !important;
  box-shadow: 0px 7px 11px 0px rgba(104, 79, 56, 0.13) !important;
}

//active card design
.active-card {
  border: 1px solid #ec7200 !important;
}

// scroll bar
::-webkit-scrollbar {
  width: .45rem;
  height: .45rem;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(27, 27, 27, 0.171);
  border-radius: 41px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.tooltip-brand-label {
  color: #FFF;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.tooltip-data-label {
  color: #FFF;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.tooltip-div {
  // display: flex;
  border-bottom: 1px solid #393939;
}

@media (min-resolution: 120dpi) {
  .tooltip-brand-label {
    font-size: 1.25rem;
  }
  
  .tooltip-data-label {
    font-size: 1rem;
  }
}

.pl-2rem {
  padding-left: 2rem !important;
}

.projected-text {
  color: var(--neutral-body, #474747);
  text-align: center;
  font-size: 1rem;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}

.disable-backdrop-click {
  pointer-events: none;
}

.modal-backdrop {
  display: none !important;
}

.dialog-backdrop {
  background-color: rgb(255, 255, 255);
  overflow: hidden;
}